<template>
    <v-container
            id="user-profile"
            fluid
            tag="section"
    >
        <v-row justify="center">
            <v-col
                    cols="12"
                    md="10"
            >
                <base-material-card>
                    <template v-slot:heading>
                        <div class="display-2 font-weight-light">
                            Form Produk
                        </div>

                        <div class="subtitle-1 font-weight-light">
                            Tambah / Ubah data Produk
                        </div>
                    </template>

                    <v-form ref="form">
                        <v-container class="py-0">
                            <v-row>
                                <input type="hidden" name="id" id="id" v-model="produk.id"/>
                                <v-col
                                        cols="12"
                                        md="6"
                                >
                                    <v-text-field
                                            class="purple-input"
                                            label="Nama"
                                            v-model="produk.name"
                                    />
                                </v-col>

                                <v-col
                                        cols="12"
                                        md="6"
                                >
                                    <v-text-field
                                            label="Kategori"
                                            class="purple-input"
                                            readonly
                                            v-model="produk.category_name"
                                    >
                                        <v-icon slot="append" color="green" size="30" @click="openModalKategori">
                                            mdi-magnify
                                        </v-icon>
                                    </v-text-field>
                                </v-col>
                                <!--<v-col-->
                                <!--cols="12" md="1">-->
                                <!--<v-btn color="secondary" raised x-small dark v-on:click="openModalKategori">-->
                                <!--<v-icon>mdi-television</v-icon>-->
                                <!--</v-btn>-->
                                <!--</v-col>-->

                                <v-col
                                        cols="12"
                                        md="4"
                                >
                                    <v-text-field
                                            label="Kode"
                                            class="purple-input"
                                            v-model="generateCode"
                                            readonly
                                            :rules="[rules.required]"
                                    />
                                </v-col>
                                <v-col
                                        cols="12"
                                        md="4"
                                >
                                    <v-text-field
                                            label="Satuan"
                                            class="purple-input"
                                            readonly
                                            v-model="produk.uom_name"
                                    >
                                        <v-icon slot="append" color="green" size="30" @click="openModalUom">mdi-magnify
                                        </v-icon>
                                    </v-text-field>
                                </v-col>
                                <v-col
                                        cols="12"
                                        md="4"
                                >
                                    <v-text-field
                                            label="Harga"
                                            class="purple-input"
                                            prefix="Rp"
                                            v-model="produk.retail_price"
                                    />
                                    <input type="hidden" v-model="produk.retail_price" v-money="money" />
                                </v-col>
                                <v-col
                                        cols="12"
                                        md="4"
                                >
                                    <v-textarea
                                            label="Deskripsi"
                                            class="purple-input"
                                            v-model="produk.desc"
                                    />
                                </v-col>
                                <v-col
                                        cols="12"
                                        md="5"
                                        justify="center"
                                >
                                    <v-file-input
                                            label="Gambar"
                                            class="purple-input"
                                            v-model="produk.image"
                                            accept="image/*"
                                            @change="onFileChange"
                                    >
                                        <template v-slot:selection="{text}">
                                            <v-chip
                                                label
                                                color="primary">
                                                {{text}}
                                            </v-chip>
                                        </template>
                                    </v-file-input>
                                    <v-img position="center center" v-if="produk.image_path !== '' && produk.image_path" :src="showImgProduct"
                                        max-width="150" max-height="150" ratio="1" contain/>
                                </v-col>
                                <v-col
                                        cols="12"
                                        md="3"
                                >
                                    <v-checkbox
                                            v-model="produk.is_concoction"
                                            label="Komposisi"
                                    ></v-checkbox>
                                </v-col>

                                <v-col
                                        cols="12"
                                        md="12"
                                        v-if="produk.is_concoction">
                                    <base-subheading subheading="Komposisi Produk" />
                                    <v-col
                                            cols="12"
                                            md="12"
                                    >
                                        <v-simple-table dense>
                                            <template v-slot:default>
                                            <thead>
                                            <tr>
                                                <th class="primary--text text-center" width="50">
                                                    No
                                                </th>
                                                <th class="primary--text text-left">
                                                    Barang
                                                </th>
                                                <th class="primary--text text-left" width="150">
                                                    Takaran
                                                </th>
                                                <th class="primary--text text-center" width="100">
                                                    Hapus
                                                </th>
                                            </tr>
                                            </thead>

                                            <tbody>
                                            <tr v-for="(item, index) in produk.concoction" :key="index">
                                                <td class="text-center">{{(index + 1)}}.
                                                    <input type="hidden" name="id_concotion"
                                                           id="id_concotion" v-model="item.id"/>
                                                    <input type="hidden" name="product_id" id="product_id"
                                                           v-model="item.fk_product_id"/>
                                                </td>
                                                <td>
                                                    <v-text-field
                                                            class="purple-input"
                                                            readonly
                                                            v-model="item.goods_name"
                                                    >
                                                        <v-icon slot="append" color="green" size="30"
                                                                @click="openModalBarang(index)">mdi-magnify
                                                        </v-icon>
                                                        <!--<span slot="append" color="red" size="30"-->
                                                        <!--@click="openModalBarang(index)">open-->
                                                        <!--</span>-->
                                                    </v-text-field>
                                                </td>
                                                <td>
                                                    <v-text-field
                                                            class="purple-input"
                                                            v-model="item.qty"
                                                            :suffix="item.uom_code"
                                                            type="number"
                                                    />
                                                </td>
                                                <td class="text-center">
                                                    <v-icon
                                                            color="error"
                                                            class="mx-1"
                                                            @click="hapus(index)"
                                                    >
                                                        mdi-close
                                                    </v-icon>
                                                </td>
                                            </tr>
                                            </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-col>
                                    <v-col
                                            cols="12"
                                            class="text-center"
                                    >
                                        <v-btn
                                                color="success"
                                                class="mr-0"
                                                @click="tambahKomposisi"
                                        >
                                            <v-icon left>mdi-plus</v-icon>
                                            Tambah
                                        </v-btn>
                                    </v-col>
                                </v-col>


                                <v-col
                                        cols="12"
                                        class="text-right"
                                >
                                    <v-btn
                                            color="success"
                                            class="mr-0"
                                            @click="simpan"
                                    >
                                        Simpan
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </base-material-card>
                <v-dialog v-model="modalKategori" persistent min-width="350" max-width="50%">
                    <v-container>
                        <v-row>
                            <v-col>
                                <base-material-card>
                                    <template v-slot:heading>
                                        <div class="display-2 font-weight-light">
                                            <v-row>
                                                <v-col cols="12" md="11">
                                                    Daftar Kategori Produk
                                                </v-col>
                                                <v-col cols="12" md="1" class="text-right">
                                                    <v-icon @click="modalKategori = false">mdi-close-circle</v-icon>
                                                </v-col>
                                            </v-row>
                                        </div>

                                        <div class="subtitle-1 font-weight-light">
                                        </div>
                                    </template>
                                    <v-row>
                                        <v-col cols="12"
                                               lg="6">
                                            <v-text-field
                                                    v-model="searchKategori"
                                                    label="Search"
                                                    single-line

                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <div class="py-1"/>
                                    <v-data-table
                                            :headers="headersKategori"
                                            :items="itemsKategori"
                                            :search="searchKategori"
                                    >
                                        <template v-slot:item.action="{ item }">
                                            <v-icon class="mx-1" color="green darken-2" @click="pilihKategori(item)">
                                                mdi-check-bold
                                            </v-icon>
                                        </template>
                                    </v-data-table>

                                    <v-row justify="end">
                                        <v-col cols="12"
                                               lg="2">
                                            <v-btn color="blue darken-1" text @click="modalKategori = false">Tutup
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                        </v-col>
                                    </v-row>


                                </base-material-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-dialog>
                <v-dialog v-model="modalUom" persistent min-width="350" max-width="50%">
                    <v-container>
                        <v-row>
                            <v-col>
                                <base-material-card>
                                    <template v-slot:heading>
                                        <div class="display-2 font-weight-light">
                                            <v-row>
                                                <v-col cols="12" md="11">
                                                    Daftar Satuan Stok
                                                </v-col>
                                                <v-col cols="12" md="1" class="text-right">
                                                    <v-icon @click="modalUom = false">mdi-close-circle</v-icon>
                                                </v-col>
                                            </v-row>
                                        </div>

                                        <div class="subtitle-1 font-weight-light">
                                        </div>
                                    </template>
                                    <v-row>
                                        <v-col cols="12"
                                               lg="6">
                                            <v-text-field
                                                    v-model="searchUom"
                                                    label="Search"
                                                    single-line

                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <div class="py-1"/>
                                    <v-data-table
                                            :headers="headersUom"
                                            :items="itemsUom"
                                            :search="searchUom"
                                    >
                                        <template v-slot:item.action="{ item }">
                                            <v-icon class="mx-1" color="green darken-2" @click="pilihUom(item)">
                                                mdi-check-bold
                                            </v-icon>
                                        </template>
                                    </v-data-table>

                                    <v-row justify="end">
                                        <v-col cols="12"
                                               lg="2">
                                            <v-btn color="blue darken-1" text @click="modalUom = false">Tutup
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                        </v-col>
                                    </v-row>


                                </base-material-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-dialog>
                <v-dialog v-model="dialog" persistent max-width="290">
                    <v-card>
                        <v-card-text class="text-center">
                            Loading
                            <v-spacer></v-spacer>
                            <v-progress-linear
                                    indeterminate
                                    rounded
                                    color="yellow darken-2"
                            ></v-progress-linear>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="modalBarang" persistent min-width="350" max-width="70%">
                    <v-container>
                        <v-row>
                            <v-col>
                                <base-material-card>
                                    <template v-slot:heading>
                                        <div class="display-2 font-weight-light">

                                            <v-row>
                                                <v-col cols="12" md="11">
                                                    Daftar Barang
                                                </v-col>
                                                <v-col cols="12" md="1" class="text-right">
                                                    <v-icon @click="modalBarang = false">mdi-close-circle</v-icon>
                                                </v-col>
                                            </v-row>

                                        </div>

                                        <div class="subtitle-1 font-weight-light">
                                        </div>
                                    </template>
                                    <v-row>
                                        <v-col cols="12"
                                               lg="6">
                                            <v-text-field
                                                    v-model="searchBarang"
                                                    label="Search"
                                                    single-line

                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <div class="py-1"/>
                                    <v-data-table
                                            :headers="headersBarang"
                                            :items="itemsBarang"
                                            :search="searchBarang"
                                    >
                                        <template v-slot:item.action="{ item }">
                                            <v-icon class="mx-1" color="green darken-2" @click="pilihBarang(item)">
                                                mdi-check-bold
                                            </v-icon>
                                            <!--<span class="mx-1" color="green darken-2"-->
                                            <!--@click="pilihBarang(item)">Pilih-->
                                            <!--</span>-->
                                        </template>
                                    </v-data-table>

                                    <v-row justify="end">
                                        <v-col cols="12"
                                               lg="2">
                                            <v-btn color="blue darken-1" text @click="modalBarang = false">Tutup
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                        </v-col>
                                    </v-row>


                                </base-material-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-dialog>
                <v-snackbar
                        v-model="snackbar"
                        :color="color"
                        :top="true"
                >
                    <span>{{message}}</span>
                    <v-btn
                            dark
                            icon
                            @click="snackbar = false"
                    >
                        <v-icon>
                            $vuetify.icons.cancel
                        </v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {ApiUrl, BaseUrl} from '../../../../config/constant'
    import axios from 'axios'
    import {
        mapState,
    } from 'vuex'
    import {Money} from 'v-money'

    export default {
        name: 'FormProducts',
        components: {
            Money
        },
        data() {
            return {
                snackbar: false,
                dialog: false,
                message: '',
                color: 'success',
                money: {
                    decimal: ',',
                    thousands: '.',
                    prefix: '',
                    suffix: '',
                    precision: 0,
//                    masked: false /* doesn't work with directive */
                },
                produk: {
                    id: null,
                    fk_product_category_id: 0,
                    category_name: '',
                    code: '',
                    oriCode: '',
                    name: '',
                    desc: '',
                    stock: 0,
                    retail_price: 0,
                    image: null,
                    image_path: '',
                    fk_uom_id: 0,
                    uom_name: '',
                    is_concoction: 0,
                    concoction: [
                        {
                            id: null,
                            fk_goods_id: 0,
                            goods_name: '',
                            uom_code: '',
                            qty: 0,
                            fk_product_id: 0
                        }
                    ]
                },
                deletedItems: [],
                modalKategori: false,
                searchKategori: '',
                headersKategori: [
                    {
                        sortable: false,
                        text: 'No',
                        value: 'no',
                        align: 'center',
                        width: 50,
                    },
                    {
                        sortable: true,
                        text: 'Nama',
                        filterable: true,
                        value: 'name',
                        width: 200,
                    },
                    {
                        sortable: true,
                        text: 'Kode',
                        filterable: true,
                        value: 'code',
                        width: 70,
                    },
                    {
                        sortable: true,
                        text: 'Deskripsi',
                        filterable: true,
                        value: 'desc',
                        align: 'start',
                        width: 200,
                    },
                    {
                        sortable: false,
                        text: 'Pilih',
                        value: 'action',
                        align: 'center',
                        width: 70
                    },
                ],
                itemsUom: [],
                modalUom: false,
                searchUom: '',
                headersUom: [
                    {
                        sortable: false,
                        text: 'No',
                        value: 'no',
                        align: 'center',
                        width: 50,
                    },
                    {
                        sortable: true,
                        text: 'Nama',
                        filterable: true,
                        value: 'name',
                        width: 300,
                    },
                    {
                        sortable: true,
                        text: 'Kode',
                        filterable: true,
                        value: 'code',
                        align: 'start',
                        width: 100,
                    },
                    {
                        sortable: false,
                        text: 'Pilih',
                        value: 'action',
                        align: 'center',
                        width: 70
                    },
                ],
                itemsKategori: [],
                modalBarang: false,
                searchBarang: '',
                itemsBarang: [],
                headersBarang: [
                    {
                        sortable: false,
                        text: 'No',
                        value: 'no',
                        align: 'center',
                        width: 50,
                    },
                    {
                        sortable: true,
                        text: 'Nama',
                        filterable: true,
                        value: 'name',
                        width: 200,
                    },
                    {
                        sortable: true,
                        text: 'Deskripsi',
                        filterable: true,
                        value: 'desc',
                        align: 'start',
                        width: 200,
                    },
                    {
                        sortable: true,
                        text: 'Kategori',
                        filterable: true,
                        value: 'category_name',
                        align: 'start',
                        width: 200,
                    },
                    {
                        sortable: true,
                        text: 'Stok',
                        filterable: true,
                        value: 'stock',
                        align: 'start',
                        width: 100,
                    },
                    {
                        sortable: true,
                        text: 'Satuan',
                        filterable: true,
                        value: 'uom_name',
                        align: 'start',
                        width: 100,
                    },
                    {
                        sortable: false,
                        text: 'Aksi',
                        value: 'action',
                        align: 'center',
                        width: 70
                    },
                ],
                indexPilih: 0,
                isLoadData: false,
                rules: {
                    required: value => !!value || 'Required.',
                    min: v => v.length >= 6 || 'Min 6 characters',
                },
            }
        },
        computed: {
            ...mapState(['currentUser']),
            generateCode: function(){
//                this.produk.retail_price += 10
                if(this.produk.name != '' && !this.isLoadData){
                    let split = this.produk.name.split(' ')
                    let codename = ''
                    let resCode = ''
                    split.forEach(function (item, i) {
                        codename += item.substr(0,2)
                    })
                    if(this.produk.oriCode != '') {
                        let splitCode = this.produk.oriCode.split('-')
                        resCode = splitCode[0]+codename.toUpperCase()+splitCode[1]
                    } else {
                        resCode = codename.toUpperCase()
                    }
                    this.produk.code = resCode
                    return this.produk.code
                } else if (this.isLoadData && this.produk.code != '') {
                    this.isLoadData = false
                    return this.produk.code
                }

            },
            showImgProduct: function(){
                if(this.produk.image_path && this.produk.image_path != '' && !this.produk.image){
                    let imgUrl = BaseUrl+this.produk.image_path
                    return imgUrl
                } else
                    return this.produk.image_path
            }
        },
//        watch: {
//            produk: {
//                handler: function (val) {
//                    console.log(val)
//                    console.log(this.produk.code.length)
////                    if (this.produk.code.length > 1)
////                    this.produk.code =
//                },
//                deep: true
//            }


//        },
        mounted: function () {
            this.dataKategori()
            this.dataUom()
            this.dataBarang()
            let id = this.$route.params.id
            console.log('edit')
            console.log(id)
            console.log(this.produk)
            if (id !== undefined) {
                this.dataProduk(id)
            }

        },
        methods: {
            openModalKategori: function () {
                this.modalKategori = true
            },
            openModalUom: function () {
                this.modalUom = true
            },
            dataKategori: function (e) {
                let token = this.currentUser.token;
                let header = {
                    'Authorization': 'Bearer ' + token,
                    'Content-type': 'application/json'
                }
                axios
                ({method: 'get', url: ApiUrl + 'productscategory', headers: header})
                    .then(response => {
                        console.log(response)
//                        this.dialog = false
                        if (response.data.success) {
                            this.itemsKategori = response.data.data
                        }
                    })
                    .catch(error => (
                        console.log(error)
                    ))
            },
            pilihKategori: function (item) {
                console.log('pilih kategori')

                this.produk.category_name = item.name
                this.produk.fk_product_category_id = item.id
                let number = parseInt(item.sequence_number)+1
                console.log(number.toString().length)
                if(number.toString().length == 1)
                    number = '0'+number
                this.produk.code = item.code+'-'+number
                this.produk.oriCode = item.code+'-'+number
                this.generateCode
                this.modalKategori = false
            },
            openModalBarang: function (i) {
                this.modalBarang = true
                this.indexPilih = i
            },
            dataBarang: function (e) {
                let token = this.currentUser.token;
                let header = {
                    'Authorization': 'Bearer ' + token,
                    'Content-type': 'application/json',
                }
                axios
                ({method: 'get', url: ApiUrl + 'goods', headers: header})
                    .then(response => {
                        console.log(response)
//                        this.dialog = false
                        if (response.data.success) {
                            this.itemsBarang = response.data.data
                        }
                    })
                    .catch(error => (
                        console.log(error)
                    ))
            },
            pilihBarang: function (item) {
                this.produk.concoction[this.indexPilih].goods_name = item.name
                this.produk.concoction[this.indexPilih].fk_goods_id = item.id
                this.produk.concoction[this.indexPilih].uom_code = item.uom_code
                if(this.produk.id && this.produk.id != 0)
                    this.produk.concoction[this.indexPilih].fk_product_id = this.produk.id
                console.log(this.produk.concoction)
                this.modalBarang = false
            },
            dataProduk: function (id) {
                let token = this.currentUser.token;
                let header = {
                    'Authorization': 'Bearer ' + token,
                    'Content-type': 'application/json'
                }
                this.isLoadData = true
                this.dialog = true
                axios
                ({method: 'get', url: ApiUrl + 'products/' + id, headers: header})
                    .then(response => {
                        console.log(response.data.data)
                        this.dialog = false

                        if (response.data.success) {
                            this.produk.id = response.data.data.id
                            this.produk.category_name = response.data.data.category_name
                            this.produk.code = response.data.data.code
                            this.produk.oriCode = response.data.data.category.code+'-'+response.data.data.code.substr((response.data.data.code.length-2), 2)
                            this.produk.fk_product_category_id = response.data.data.fk_product_category_id
                            this.produk.name = response.data.data.name
                            this.produk.desc = response.data.data.desc
                            this.produk.retail_price = response.data.data.retail_price
                            console.log(this.produk.retail_price)
                            this.produk.image_path = response.data.data.image_path
                            this.produk.fk_uom_id = response.data.data.fk_uom_id
                            this.produk.uom_name = response.data.data.uom_name
                            this.produk.is_concoction = response.data.data.is_concoction
                            if(response.data.data.concoction){
                                if(response.data.data.concoction.length > 0){
                                    let t = this
                                    response.data.data.concoction.forEach(function(item, i){
                                        if(t.produk.concoction.length > i) {
                                            t.produk.concoction[i].goods_name = item.goods.name
                                            t.produk.concoction[i].qty = item.qty
                                            t.produk.concoction[i].fk_goods_id = item.fk_goods_id
                                            t.produk.concoction[i].fk_product_id = item.fk_product_id
                                            t.produk.concoction[i].uom_code = item.goods.uom.code
                                            t.produk.concoction[i].id = item.id
                                        } else {
                                            t.produk.concoction.push({
                                                id: item.id,
                                                fk_goods_id: item.fk_goods_id,
                                                goods_name: item.goods.name,
                                                uom_code: item.goods.uom.code,
                                                qty: item.qty,
                                                fk_product_id: item.fk_product_id
                                            })
                                        }
                                    })
                                }
                            }
                        }
                    })
                    .catch(error => {
                        this.dialog = false
                        console.log(error)
                    })
            },
            dataUom: function (e) {
                let token = this.currentUser.token;
                let header = {
                    'Authorization': 'Bearer ' + token,
                    'Content-type': 'application/json'
                }
                axios
                ({method: 'get', url: ApiUrl + 'uom', headers: header})
                    .then(response => {
                        console.log(response)
//                        this.dialog = false
                        if (response.data.success) {
                            this.itemsUom = response.data.data
                        }
                    })
                    .catch(error => (
                        console.log(error)
                    ))
            },
            pilihUom: function (item) {
                console.log('pilih uom')
                console.log(item)
                this.produk.uom_name = item.name
                this.produk.fk_uom_id = item.id
                this.modalUom = false
            },
            simpan: function () {
                console.log(this.produk.retail_price.replace(/\./g,''))
                this.produk.retail_price = this.produk.retail_price.replace(/\./g,'')
                if (this.produk.name && this.produk.name !== '' && this.produk.fk_product_category_id && this.produk.fk_product_category_id !== 0 &&
                    this.produk.fk_uom_id && this.produk.fk_uom_id !== 0) {
                    if(this.produk.is_concoction){
                        let t = this
                        let errorValidasi = false
                        this.produk.concoction.some(function (val, i) {
                            if (val.fk_goods_id == 0 || !val.fk_goods_id) {
                                t.snackbar = true
                                t.color = 'error'
                                t.message = 'Jika komposisi dipilih, maka produk harus memiliki nama bahan'
                                errorValidasi = true
                                return false
                            }

                            if (val.qty == 0 || !val.qty) {
                                t.snackbar = true
                                t.color = 'error'
                                t.message = 'Jika komposisi dipilih, maka produk harus memiliki takaran bahan'
                                errorValidasi = true
                                return false
                            }
                        })

                        if(errorValidasi)
                            return false;
                    }

//                    alert('dasd')

                    let token = this.currentUser.token;
                    let header = {
                        'Authorization': 'Bearer ' + token,
                        'Content-type': 'multipart/form-data'
                    }

                    const formData = new FormData();
                    formData.append('image', this.produk.image)
                    if(this.produk.id)
                        formData.append('product[id]', this.produk.id)
                    formData.append('product[name]', this.produk.name)
                    formData.append('product[desc]', this.produk.desc)
                    formData.append('product[fk_uom_id]', this.produk.fk_uom_id)
                    formData.append('product[code]', this.produk.code)
                    formData.append('product[is_concoction]', this.produk.is_concoction?1:0)
                    formData.append('product[fk_product_category_id]', this.produk.fk_product_category_id)
                    formData.append('product[retail_price]', this.produk.retail_price)
                    if(this.produk.is_concoction) {
                        this.produk.concoction.forEach(function (val, i) {
                            if (val.fk_goods_id !== 0 && val.fk_goods_id) {
                                Object.keys(val).forEach(function (item) {
                                    if (item !== 'goods_name' && item !== 'uom_code') {
                                        if ((item === 'id' && val[item]) || (item !== 'id'))
                                            formData.append('concoction[' + i + '][' + item + ']', val[item])
                                    }
                                });
                            }

                        })
                    }
                    if(this.deletedItems) {
                        this.deletedItems.forEach(function (val, i) {
                            formData.append('delete[' + i + ']', val)
                        })
                    }
//                    formData.append('concoction', JSON.stringify(this.produk.concoction))

                    this.dialog = true
                    axios
                    ({method: 'post', url: ApiUrl + 'products/simpan', data: formData, headers: header})
                        .then(response => {
                            console.log(response)
                            this.dialog = false
                            if (response.data.success) {
                                this.snackbar = true
                                this.color = 'success'
                                this.message = 'Berhasil simpan                    '
                                this.produk = {
                                    fk_goods_category_id: 0,
                                    category_name: '',
                                    name: '',
                                    desc: '',
                                    code: '',
                                    oriCode: '',
                                    stock: 0,
                                    fk_uom_id: 0,
                                    uom_name: '',
                                    is_direct_selling: 0,
                                    id: null,
                                }
                                this.$refs.form.resetValidation()
                            } else {
                                this.snackbar = true
                                this.color = 'error'
                                this.message = 'Gagal simpan                    '
                            }
                        })
                        .catch(error => {
                            this.dialog = false
                            console.log(error.message, error.response)
                            this.snackbar = true
                            this.color = 'error'
                            this.message = 'Gagal simpan'
                        })
                } else {
                    this.snackbar = true
                    this.color = 'error'
                    this.message = 'Nama, kategori dan satuan stok harus diisi'
                }
            },
            tambahKomposisi: function () {
                console.log(this.produk.concoction)
                this.produk.concoction.push({
                    id: 0,
                    fk_goods_id: 0,
                    goods_name: '',
                    uom_code: '',
                    qty: 0,
                    fk_product_id: this.produk.id ? this.produk.id : 0
                })
            },
            hapus: function (index) {
                if(this.produk.concoction[index].id) {
                    this.deletedItems.push(this.produk.concoction[index].id)
                }

                if (this.produk.concoction.length > 1)
                    this.produk.concoction.splice(index, 1)
                else {
                    this.produk.concoction[index].id = null
                    this.produk.concoction[index].fk_goods_id = 0
                    this.produk.concoction[index].goods_name = ''
                    this.produk.concoction[index].uom_code = ''
                    this.produk.concoction[index].qty = 0
                    this.produk.concoction[index].fk_product_id = 0
                }



            },
            onFileChange(e) {
                console.log(e)
                if(e)
                    this.produk.image_path = URL.createObjectURL(e);
                else
                    this.produk.image_path = ''

            }
        }
    }
</script>
